import Typography from '@gav/typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {clean} from '../PrintActions';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

class PrintComponent extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.printContent === null && this.props.printContent) {
      setTimeout(() => {
        window.print();
        setTimeout(() => this.props.clean(), 100);
      }, 200);
    }
  }

  static propTypes = {
    printContent: PropTypes.string,
    clean: PropTypes.func,
    loading: PropTypes.bool,
    classes: PropTypes.object
  };

  render() {
    const {printContent, loading, classes} = this.props;
    if (!printContent && !loading) {
      return null;
    }

    if (loading) {
      return (
        <Modal open>
          <div className={classes.paper}>
            <Typography family="fontSecondary" size={20} weight="medium" lineHeight={36}>
              Preparando para impressão
            </Typography>
            <Typography family="fontSecondary" size={16} lineHeight={22}>
              Estamos buscando os valores necessários para imprimir a página. Por favor, aguarde.
            </Typography>
            <LinearProgress />
          </div>
        </Modal>
      );
    }

    return (
      <div className="print">
        <div dangerouslySetInnerHTML={{__html: this.props.printContent}} />
      </div>
    );
  }
}

export default connect(state => ({...state.print}), {clean})(withStyles(styles)(PrintComponent));
