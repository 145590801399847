import Dialog from '@gav/dialog';
import Typography from '@gav/typography';
import {Trans} from '@lingui/react';
import {Checkbox, List, ListItem, ListItemText, Accordion, AccordionDetails, AccordionSummary, Badge} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withStyles} from '@material-ui/core/styles';
import {ExpandMore as ExpandMoreIcon, Store as StoreIcon} from '@material-ui/icons';
import classnames from 'classnames';
import {concat, filter, includes, isEqual, map, size} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';

import styles from '../styles/FilterStyles';

const AbstractFilter = props => {
  const {classes, customContent, expandedPanel, isMaxHeight, handleChange, icon, useDialog, loading, options, primaryButtonAction, primaryButtonText, selected, showSelectAll, title} = props;

  const [expanded, setExpanded] = useState(expandedPanel && !useDialog);

  const selectedSize = selected?.length;
  const optionsSize = options?.length;
  const selectedAll = selectedSize === optionsSize;
  const indeterminated = selectedSize > 0 && selectedSize < optionsSize;

  const handleToggle = (id, checked) => () => {
    let newSelected = selected;
    if (checked) {
      newSelected = filter(newSelected, it => !isEqual(it, id));
    } else {
      newSelected = concat(newSelected, id);
    }
    handleChange(newSelected);
  };

  const handleAll = () => {
    let newSelected = [];

    if (!selectedAll) {
      newSelected = options.map(it => it.id);
    }

    handleChange(newSelected);
  };

  const togglePainel = () => {
    setExpanded(!expanded);
  };

  const handleIcon = () => {
    if (loading) {
      return <CircularProgress color="primary" size={24} thickness={3.5} />;
    }
    return <ExpandMoreIcon />;
  };

  const renderEntry = entry => {
    const checked = includes(selected, entry.id);
    return (
      <React.Fragment key={entry.id}>
        <ListItem button dense onClick={handleToggle(entry.id, checked)} className={classes.checkboxesList}>
          <Checkbox color="primary" disableRipple checked={checked} className={classes.checkboxes} />
          <ListItemText primary={<Trans id={entry.name} />} />
        </ListItem>
      </React.Fragment>
    );
  };

  const renderContent = () => {
    return (
      <>
        {showSelectAll && (
          <div className={classes.checkboxAll}>
            <ListItem dense button onClick={handleAll} className={classes.checkboxesList}>
              <Checkbox color="primary" checked={selectedAll} indeterminate={indeterminated} disableRipple className={classes.checkboxes} />
              <Typography family="fontSecondary" size={16} color="neutral.level2" className={classes.titles}>
                <Trans id="common.filters.selectAll">Selecionar todos</Trans>
              </Typography>
            </ListItem>
          </div>
        )}
        {options && <List classes={{root: classes.listView}}>{map(options, renderEntry)}</List>}
        {customContent}
      </>
    );
  };

  const primaryButton = () => {
    primaryButtonAction && primaryButtonAction();
    togglePainel();
  };

  const renderDetails = () => {
    if (useDialog) {
      return (
        <Dialog fullScreen onClose={togglePainel} open={expanded} primaryButtonAction={primaryButton} primaryButtonText={primaryButtonText} title={title}>
          {renderContent()}
        </Dialog>
      );
    }
    const expandedClasses = classnames({
      [classes.expanded]: true,
      [classes.expandedFullHeight]: isMaxHeight
    });

    return <AccordionDetails classes={{root: expandedClasses}}>{renderContent()}</AccordionDetails>;
  };

  return (
    <Accordion classes={{root: classes.expandedPanel}} expanded={expanded} onChange={togglePainel}>
      <AccordionSummary expandIcon={handleIcon()} classes={{root: classes.panelSummary}}>
        <div className={classes.icon}>
          <Badge badgeContent={size(selected)} color="error">
            {icon}
          </Badge>
        </div>
        <Typography family="fontSecondary" size={16} color="neutral.level2" className={classes.titles}>
          {title}
        </Typography>
      </AccordionSummary>
      {!loading && expanded && renderDetails()}
    </Accordion>
  );
};

AbstractFilter.defaultProps = {
  expandedPanel: false,
  icon: <StoreIcon />,
  isMaxHeight: false,
  useDialog: false,
  loading: false,
  primaryButtonText: 'Fechar'
};

AbstractFilter.propTypes = {
  classes: PropTypes.object,
  customContent: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.element]),
  expandedPanel: PropTypes.bool,
  handleChange: PropTypes.func,
  icon: PropTypes.shape({}).isRequired,
  isMaxHeight: PropTypes.bool,
  loading: PropTypes.bool,
  options: PropTypes.array,
  primaryButtonAction: PropTypes.func,
  primaryButtonText: PropTypes.string,
  selected: PropTypes.array,
  showSelectAll: PropTypes.bool,
  title: PropTypes.string.isRequired,
  useDialog: PropTypes.bool
};

export default withStyles(styles)(AbstractFilter);
