import Typography from '@gav/typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  text: {
    padding: `${theme.spacing(0.5)}px 0px`
  }
});

class LoadingModalComponent extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    subheading: PropTypes.string,
    title: PropTypes.string
  };

  static defaultProps = {
    title: 'Carregando arquivo',
    subheading: 'Aguarde, seu arquivo está sendo enviado para o servidor.'
  };

  render() {
    const {classes, subheading, title} = this.props;
    return (
      <Modal open>
        <div className={classes.paper}>
          <Typography className={classes.text} family="fontSecondary" size={18} weight="medium">
            {title}
          </Typography>
          <Typography className={classes.text} family="fontSecondary" size={14}>
            {subheading}
          </Typography>
          <LinearProgress />
        </div>
      </Modal>
    );
  }
}

export default withStyles(styles)(LoadingModalComponent);
