import Typography from '@gav/typography';
import {Trans} from '@lingui/react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';

import {formatDateFns, getDateFns} from '../../app/dateFns';

const styles = theme => ({
  root: {
    margin: theme.spacing(2),
    float: 'left',
    position: 'relative',
    width: 100
  },
  alignToCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  fieldSelect: {
    marginTop: 5,
    textAlign: 'center'
  },
  title: {
    textAlign: 'center'
  }
});

class YearPicker extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    show: PropTypes.bool,
    value: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.elements = [];
    let yearNow = formatDateFns(getDateFns(), 'yyyy');
    for (let i = yearNow; i > yearNow - 3; i--) {
      const value = `${i}`;
      this.elements.push(
        <MenuItem value={value} key={value}>
          {value}
        </MenuItem>
      );
    }
  }

  handleChange = ({target}) => {
    const {value} = target;
    this.props.onChange(value);

    ReactGA.event({
      category: 'Filters',
      action: 'Change year',
      label: `${value}`
    });
  };

  render() {
    const {classes, show} = this.props;

    if (!show) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div className={classes.alignToCenter}>
          <Typography className={classes.title} family="fontSecondary" size={16} lineHeight={24} color="neutral.level5">
            <Trans id="common.filter.year">ANO</Trans>
          </Typography>
          <Select className={classes.fieldSelect} onChange={this.handleChange} value={this.props.value}>
            {this.elements}
          </Select>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(YearPicker);
