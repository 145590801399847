import DateFnsUtils from '@date-io/date-fns';
import Typography from '@gav/typography';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import {DatePicker as Picker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format as formatDate, fromUnixTime} from 'date-fns';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt-BR';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isMobile} from '../app/config';
import {formatDateFns} from '../app/dateFns';
import styles from '../ranking/styles/topStyles';

class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText(date) {
    return formatDate(date, 'd MMM yyyy', {locale: this.locale});
  }
}

class DatePicker extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    customStyle: PropTypes.object,
    disabled: PropTypes.bool,
    disableFuture: PropTypes.bool,
    error: PropTypes.bool,
    format: PropTypes.string,
    isMobile: PropTypes.bool,
    labelFunc: PropTypes.func,
    language: PropTypes.string,
    maxDate: PropTypes.number,
    maxDateMessage: PropTypes.string,
    minDate: PropTypes.number,
    monthly: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    renderDay: PropTypes.func,
    shouldDisableDate: PropTypes.bool,
    sideFilter: PropTypes.bool,
    title: PropTypes.string,
    value: PropTypes.number,
    variant: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    disableFuture: true,
    format: 'dd/MM/yyyy',
    maxDateMessage: '',
    sideFilter: false,
    monthly: false
  };

  state = {
    openDatePicker: false
  };

  renderMaxDate = () => {
    if (this.props.maxDate) return fromUnixTime(this.props.maxDate);
    return undefined;
  };

  getMinDate = () => {
    if (this.props.minDate) {
      return fromUnixTime(this.props.minDate);
    }
    return undefined;
  };

  getValue = () => {
    const {value} = this.props;
    if (typeof value === 'number') {
      return fromUnixTime(value);
    }
    return value;
  };

  onClose = () => this.setState({openDatePicker: false});

  onOpen = () => this.setState({openDatePicker: true});

  renderButton = () => {
    const {classes, format, isMobile, sideFilter} = this.props;
    return (
      <Button className={!sideFilter ? classes.date : classes.dateButton} onClick={this.onOpen}>
        <DateRangeIcon className={sideFilter ? classes.iconSideFilter : classes.icon} />
        <Typography family="fontSecondary" size={!isMobile ? 14 : 12} color="neutral.level2" className={sideFilter ? classes.titleDateButton : classes.title}>
          {formatDateFns(this.getValue(), format, this.props.language)}
        </Typography>
      </Button>
    );
  };

  render() {
    const {classes, customStyle, language, monthly, sideFilter, variant} = this.props;
    const locale = language === 'pt' ? pt : en;

    if (variant === 'button') {
      return (
        <div className={!sideFilter ? classes.dateContent : classes.dateButtonContent}>
          <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
            <Picker
              views={monthly ? ['month'] : undefined}
              animateYearScrolling={false}
              autoOk
              cancelLabel="Cancelar"
              disabled={this.props.disabled}
              disableFuture={this.props.disableFuture}
              format={this.props.format}
              labelFunc={this.props.labelFunc}
              leftArrowIcon={<LeftArrowIcon />}
              maxDate={this.renderMaxDate()}
              maxDateMessage={this.props.maxDateMessage}
              minDate={this.getMinDate()}
              onChange={this.props.onChange}
              onClose={this.onClose}
              open={this.state.openDatePicker}
              renderDay={this.props.renderDay}
              rightArrowIcon={<RightArrowIcon />}
              shouldDisableDate={this.props.shouldDisableDate}
              TextFieldComponent={this.renderButton}
              value={this.getValue()}
            />
          </MuiPickersUtilsProvider>
        </div>
      );
    }

    if (variant === 'form') {
      return (
        <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
          <Picker
            views={monthly ? ['month'] : undefined}
            disabled={this.props.disabled}
            disableFuture={this.props.disableFuture}
            error={this.props.error}
            format={this.props.format}
            labelFunc={this.props.labelFunc}
            maxDate={this.renderMaxDate()}
            minDate={this.getMinDate()}
            onChange={this.props.onChange}
            readOnly={this.props.readOnly}
            renderDay={this.props.renderDay}
            shouldDisableDate={this.props.shouldDisableDate}
            title={this.props.title}
            value={this.getValue()}
          />
        </MuiPickersUtilsProvider>
      );
    }

    return (
      <div className={[classes.cardContent, classes.cardContentDate].join(' ')}>
        <div className={classes.inputGroup}>
          <MuiPickersUtilsProvider utils={LocalizedUtils} locale={locale}>
            <Picker
              views={monthly ? ['month'] : undefined}
              animateYearScrolling={false}
              autoOk
              cancelLabel="Cancelar"
              disabled={this.props.disabled}
              disableFuture={this.props.disableFuture}
              format={this.props.format}
              InputProps={{
                style: {
                  width: '120px',
                  ...customStyle
                }
              }}
              error={this.props.error}
              readOnly={this.props.readOnly}
              labelFunc={this.props.labelFunc}
              leftArrowIcon={<LeftArrowIcon />}
              maxDate={this.renderMaxDate()}
              maxDateMessage=""
              minDate={this.getMinDate()}
              onChange={this.props.onChange}
              renderDay={this.props.renderDay}
              rightArrowIcon={<RightArrowIcon />}
              shouldDisableDate={this.props.shouldDisableDate}
              value={this.getValue()}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filter: {...state.filter},
    isMobile: isMobile(state),
    language: state.userPreferences.language
  };
};

export default connect(mapStateToProps)(withStyles(styles)(DatePicker));
