import Typography from '@gav/typography';
import {CircularProgress} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {push} from 'connected-react-router';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {verifyToken} from './actions/LoginActions';

class Login extends React.Component {
  async componentDidMount() {
    if (this.props.user.logged === false && !this.props.user.logging) {
      const toPath = get(this.props.location, 'state.from.pathname', '/');
      if (!(await this.props.verifyToken(toPath))) {
        const callbackUrl = `${window.location.origin}${toPath}`;
        window.location = `//${window.REACT_APP_LOGIN_SERVER}?callbackUrl=${callbackUrl}`;
      }
    }
  }

  render() {
    const {classes} = this.props;
    return (
      <section className={classes.wrapper}>
        <div className={classes.mainLogoSection}>
          <img src={`${process.env.PUBLIC_URL}/imgs/grupo_avenida_logo.svg`} className={classes.avenidaLogo} alt="Logo Grupo Avenida" />
          <Typography variant="h3" color="secondary.main">
            Carregando...
          </Typography>
          <CircularProgress size={30} thickness={3.5} className={classes.progressBarStyle} />
        </div>
      </section>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object,
  location: PropTypes.object,
  verifyToken: PropTypes.func,
  user: PropTypes.object.isRequired
};

const styles = theme => ({
  wrapper: {
    position: 'fixed',
    left: 0,
    top: 0,
    height: '100%',
    width: '100%',
    background: theme.colors.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainLogoSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avenidaLogo: {
    width: 'calc(100% - 60px)',
    margin: `${theme.spacing(1)}px 0px ${theme.spacing(3)}px 0px`,
    maxWidth: '450px'
  },
  progressBarStyle: {
    display: 'block',
    position: 'relative',
    top: theme.spacing(2),
    color: theme.colors.secondary.main,
    margin: `${theme.spacing(4)}px 0px 0px 0px`
  }
});

const mapDispatchToProps = dispatch => {
  return {
    push: path => dispatch(push(path)),
    verifyToken: (path, isAdminRequired) => dispatch(verifyToken(path, isAdminRequired))
  };
};

const mapStateToProps = state => ({
  user: {...state.user},
  location: {...state.router.location}
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
