import Dialog from '@gav/dialog';
import Typography from '@gav/typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {withStyles} from '@material-ui/core/styles';
import {map, size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

class StoresActivityActivityModal extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    data: PropTypes.shape({
      entries: PropTypes.array,
      holidayName: PropTypes.string,
      holidayDate: PropTypes.string
    }),
    entries: PropTypes.array,
    status: PropTypes.string,
    message: PropTypes.string,
    loading: PropTypes.bool
  };

  state = {
    modalData: {}
  };

  renderEntries = () => {
    const {
      data: {entries}
    } = this.props;
    const items = map(entries, (entry, k) => {
      return (
        <ListItem key={k} dense>
          <Typography lineHeight={18} size={16}>
            {entry.fullName}
          </Typography>
        </ListItem>
      );
    });
    return <List>{items}</List>;
  };

  renderChildren = title => {
    const {
      classes,
      data: {entries}
    } = this.props;
    if (size(entries) === 0) {
      return (
        <Typography lineHeight={18} size={16}>
          Todas as lojas já preencheram a informação de abertura ou fechamento para o feriado <b>{title}</b>
        </Typography>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <div className={classes.title}>
            <Typography lineHeight={22} size={20}>
              Lojas pendentes*
            </Typography>
          </div>
          <div className={classes.content}>{this.renderEntries()}</div>
        </div>
        <div className={classes.description}>
          <Typography>* Lojas pendentes de definição se abrirão ou não no feriado: {title}</Typography>
        </div>
      </div>
    );
  };

  render() {
    const {
      openModal,
      closeModal,
      status,
      message,
      loading,
      data: {holidayName, holidayDate}
    } = this.props;
    let title = '';
    if (loading) {
      title = 'Carregando';
    } else if (holidayName && holidayDate) {
      title = `${holidayName} - ${holidayDate}`;
    }
    return (
      <Dialog maxWidth="sm" open={openModal} loading={loading} status={status} message={message} onClose={closeModal} title={title} secondaryButtonAction={closeModal} secondaryButtonText="Fechar">
        {this.renderChildren(title)}
      </Dialog>
    );
  }
}

const styles = {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  wrapper: {},
  title: {},
  content: {},
  description: {
    marginTop: 20
  },
  subtitle: {
    padding: '0px 0px 0px 24px',
    marginBottom: 10
  }
};

export default withStyles(styles)(StoresActivityActivityModal);
