import Typography from '@gav/typography';
import {t} from '@lingui/macro';
import {Trans} from '@lingui/react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '../../../../../../component/DatePicker';
import styles from '../../../../styles/FilterStyles';

const DateComponent = props => {
  const {classes, disabled, disableFuture, format, label, maxDate, minDate, monthly, onChange, renderDay, readOnly, error, value} = props;

  return (
    <div className={classes.panelContent}>
      <Typography family="fontSecondary" size={16} color="neutral.level5">
        <Trans id={label}>{label}</Trans>
      </Typography>
      <DatePicker
        readOnly={readOnly}
        error={error}
        disabled={disabled}
        disableFuture={disableFuture}
        format={format}
        maxDate={maxDate}
        minDate={minDate}
        monthly={monthly}
        onChange={onChange}
        renderDay={renderDay}
        value={value}
      />
    </div>
  );
};

DateComponent.defaultProps = {
  disableFuture: true,
  disabled: false,
  label: t`common.date.currentYear`,
  monthly: false
};

DateComponent.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  disableFuture: PropTypes.bool,
  format: PropTypes.string,
  label: PropTypes.string,
  maxDate: PropTypes.number,
  minDate: PropTypes.number,
  monthly: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  renderDay: PropTypes.func,
  value: PropTypes.number,
  readOnly: PropTypes.bool,
  error: PropTypes.bool
};

export default withStyles(styles)(DateComponent);
