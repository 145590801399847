import Typography from '@gav/typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Modal from '@material-ui/core/Modal';
import {withStyles} from '@material-ui/core/styles';
import {saveAs} from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {clean} from '../ExportAction';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(50),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
});

class ExcelExportComponent extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.fileContent === null && this.props.fileContent) {
      const file = new Blob([this.props.fileContent], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      saveAs(file, this.props.filename);
      setTimeout(() => this.props.clean(), 1000);
    }
  }

  render() {
    const {fileContent, loading, classes} = this.props;
    if (!fileContent && !loading) {
      return null;
    }

    if (loading) {
      return (
        <Modal open>
          <div className={classes.paper}>
            <Typography family="fontSecondary" weight="medium" size={20} lineHeight={32}>
              Preparando para exportar
            </Typography>
            <Typography family="fontSecondary" size={16} lineHeight={22}>
              Estamos preparando o arquivo Excel para exportação. Por favor, aguarde.
            </Typography>
            <LinearProgress />
          </div>
        </Modal>
      );
    }

    return <div style={{display: 'none'}} />;
  }
}

ExcelExportComponent.propTypes = {
  classes: PropTypes.object,
  filename: PropTypes.string,
  loading: PropTypes.bool,
  clean: PropTypes.func,
  fileContent: PropTypes.bool
};

export default connect(state => ({...state.export}), {clean})(withStyles(styles)(ExcelExportComponent));
