import Typography from '@gav/typography';
import {Trans} from '@lingui/react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import LogoutIcon from '@material-ui/icons/Input';
import PersonIcon from '@material-ui/icons/Person';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';

import styles from './NavBarStyles';

class UserMenu extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    username: PropTypes.string.isRequired
  };

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
    ReactGA.event({
      category: 'Navigation',
      action: 'User menu opened'
    });
  };

  handleClose = () => {
    this.setState({anchorEl: null});
    ReactGA.event({
      category: 'Navigation',
      action: 'User menu closed'
    });
  };

  logout = () => {
    ReactGA.event({
      category: 'Auth',
      action: 'User logout',
      label: localStorage.getItem('name')
    });
    this.handleClose();
    this.props.logout();
  };

  render() {
    const {classes, isMobile, username} = this.props;
    const {anchorEl} = this.state;

    return (
      <div id="topbar-user_menu">
        <IconButton
          className={classnames(anchorEl ? classes.buttonActive : null, classes.buttonCircle, isMobile ? classes.buttonCircleMobile : null)}
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          aria-label="Usuário"
          onClick={this.handleClick}
        >
          <PersonIcon />
          <span>
            <Trans id="topbar.name.user">Usuário</Trans>
          </span>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          className={classes.subMenu}
          disableAutoFocusItem
          getContentAnchorEl={null}
          onClose={this.handleClose}
          open={Boolean(anchorEl)}
        >
          <MenuItem>
            <div>
              <Typography family="fontSecondary" lineHeight={22} size={14} tag="h3" weight="700">
                {username}
              </Typography>
            </div>
          </MenuItem>
          <MenuItem className={classes.menuItem} onClick={this.logout}>
            <div className={classes.menuItemText}>
              <Trans id="topbar.user.logout">Sair</Trans>
            </div>
            <LogoutIcon className={classes.menuItemIcon} />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(UserMenu);
