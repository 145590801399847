import Button from '@gav/button';
import LoadError from '@gav/load-error';
import Select from '@gav/select';
import Typography from '@gav/typography';
import {t} from '@lingui/macro';
import {Trans} from '@lingui/react';
import {withStyles} from '@material-ui/core';
import {Add, Edit} from '@material-ui/icons';
import classnames from 'classnames';
import {get, map, size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Loading from '../../app/Loading';
import ErrorHandler from '../charts/errorHandler';
import chartsMap from '../chartsData';
import EmptyPanel from './emptyPanel';
import styles from './styles';

class MainPanel extends React.Component {
  renderChart = (chart, chartsMap) => {
    const chartData = get(chartsMap, chart.id);
    if (!chartData) {
      return (
        <Typography family="fontSecondary" size={16}>
          <Trans id="salesDashboard.discontinuedChart">Gráfico descontinuado</Trans>
        </Typography>
      );
    }
    if (!chartData.component) {
      return (
        <Typography family="fontSecondary" size={16}>
          <Trans id="salesDashboard.chartNotFound">Gráfico não encontrado</Trans>
        </Typography>
      );
    }
    const Chart = chartData.component;
    return (
      <ErrorHandler>
        <Chart name={chart.name} />
      </ErrorHandler>
    );
  };

  renderContent = () => {
    const {classes, disableEdition, editingMode, editPanel, loading, loadingError, loadingCharts, loadingChartsError, reloadPanels, reloadCharts, currentPanel, userPanels} = this.props;
    const isLoading = loading || (loadingCharts && get(currentPanel, 'id'));
    const noPanels = !editingMode && size(userPanels) < 1;
    const noChartsOnPanel = size(get(currentPanel, 'charts')) < 1;

    if (isLoading) return <Loading marginTop={0} />;
    if (loadingError) return <LoadError reloadAction={reloadPanels} />;
    if (loadingChartsError) return <LoadError reloadAction={reloadCharts} />;
    if (noPanels) return <EmptyPanel message1={t`salesDashboard.youDontHaveAnyChartYet`} message2={t`salesDashboard.clickInNewDashboardToCreate`} />;
    if (noChartsOnPanel) return <EmptyPanel message1={t`salesDashboard.youHaventAddedAnyCharts`} message2={t`salesDashboard.selectOneToAssembleYouDashboard`} />;
    const chartsMapResult = chartsMap();
    return (
      <>
        {!disableEdition && !editingMode && (
          <div className={classes.editButtonWrapper}>
            <Button variant="outline" onClick={editPanel} iconLeft={p => <Edit {...p} className={classes.editIcon} />}>
              <Trans id="salesDashboard.edit">Editar</Trans>
            </Button>
          </div>
        )}
        <div className={classes.chartsWrapper}>
          {map(currentPanel.charts, chart => {
            return (
              <div key={chart.id} className={classes.singleChartWrapper}>
                {this.renderChart(chart, chartsMapResult)}
              </div>
            );
          })}
        </div>
      </>
    );
  };

  render() {
    const {addPanel, classes, disableNewPanel, editingMode, isMobile, loading, onChangePanel, userPanels, currentPanel} = this.props;
    const disableContent = editingMode || loading;
    const noPanels = size(userPanels) < 1;

    return (
      <div className={isMobile ? classes.mainContainerMobile : classes.mainContainer}>
        <div className={classes.header}>
          <div className={classnames(classes.selectWrapper, classes.grow)}>
            <Select
              onChange={onChangePanel}
              placeholder={t`salesDashboard.myDashboards`}
              noOptionsMessage={t`salesDashboard.noDashboardsAvailable`}
              disabled={disableContent || noPanels}
              loading={loading}
              maxWidth={600}
              minWidth={150}
              getOptionValue={entry => entry.id}
              getOptionLabel={entry => entry.name}
              options={userPanels}
              value={currentPanel}
            />
          </div>
          {!disableNewPanel && (
            <div className={classes.buttonWrapper}>
              <Button variant="primary" iconLeft={Add} onClick={addPanel} disabled={disableContent}>
                <span className={classes.buttonText}>
                  <Trans id="salesDashboard.newDashboard">Novo Painel</Trans>
                </span>
              </Button>
            </div>
          )}
        </div>
        <div className={classes.content}>{this.renderContent()}</div>
      </div>
    );
  }
}

MainPanel.propTypes = {
  addPanel: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  disableNewPanel: PropTypes.bool.isRequired,
  disableEdition: PropTypes.bool.isRequired,
  editingMode: PropTypes.bool.isRequired,
  editPanel: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingError: PropTypes.bool.isRequired,
  loadingCharts: PropTypes.bool.isRequired,
  loadingChartsError: PropTypes.bool.isRequired,
  onChangePanel: PropTypes.func.isRequired,
  reloadPanels: PropTypes.func.isRequired,
  reloadCharts: PropTypes.func.isRequired,
  currentPanel: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    charts: PropTypes.array
  }),
  userPanels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  )
};

export default withStyles(styles)(MainPanel);
