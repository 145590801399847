import Button from '@gav/button';
import Dialog from '@gav/dialog';
import Typography from '@gav/typography';
import {withStyles} from '@material-ui/core';
import classnames from 'classnames';
import {get, map, size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {parseDate, formatDateFns} from '../../../../app/dateFns';
import {REGISTER_HOLIDAY} from '../../../notifications/util/notificationTypes.js';
import {loadPendingHolidays, saveData} from './actions/HolidayAlertActions';
import HolidayActivityRegisterModal from './component/HolidayActivityRegisterModal';

const HOLIDAY_LIST = 'holiday_list';
const HOLIDAY_REGISTER = 'holiday_register';

const styles = theme => ({
  dialogRoot: {},
  holidayItem: {
    borderBottom: `1px solid ${theme.colors.neutral.level9}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  nameDateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    minWidth: 300,
    paddingRight: theme.spacing(3)
  },
  mobileNameDateWrapper: {
    minWidth: 'auto'
  },
  holidayNameWrapper: {
    paddingBottom: theme.spacing(1)
  },
  buttonWrapper: {},
  loadingError: {
    minWidth: 400
  }
});

class HolidayRegisterComponent extends React.Component {
  state = {
    loading: true,
    holidays: [],
    modalData: {},
    type: HOLIDAY_LIST
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({loading: true});
    loadPendingHolidays()
      .then(payload => {
        this.setState({holidays: get(payload, 'entries', []), loading: false});
      })
      .catch(() => {
        this.setState({loading: false, status: 'error', message: 'Erro ao carregar as informações.'});
      });
  };

  saveHoliday = formData => {
    return saveData(formData).then(() => {
      this.loadData();
      this.props.checkNotificationStatus(REGISTER_HOLIDAY);
    });
  };

  parseModalData = data => {
    return {
      ...data,
      holidayDate: parseDate(data.holidayDate)
    };
  };

  editHoliday = data => () => {
    this.setState({type: HOLIDAY_REGISTER, modalData: this.parseModalData(data)});
  };

  closeRegisterModal = () => {
    this.setState({type: HOLIDAY_LIST, modalData: {}});
  };

  formatDate = date => {
    return formatDateFns(parseDate(date), 'dd/MM/yyyy');
  };

  renderHolidayRegister = () => {
    const {type, modalData, loading} = this.state;
    return <HolidayActivityRegisterModal data={modalData} openModal={type === HOLIDAY_REGISTER} closeModal={this.closeRegisterModal} onSave={this.saveHoliday} loading={loading} />;
  };

  renderListDialogContent = () => {
    const {classes, isMobile} = this.props;
    const {holidays, status} = this.state;

    if (status === 'error') return <div className={classes.loadingError}></div>;
    if (size(holidays) === 0) {
      return <Typography lineHeight={20}>Você já preencheu a informação para todos os feriados.</Typography>;
    }
    return (
      <div className={classes.dialogRoot}>
        {map(holidays, (it, k) => {
          return (
            <div key={k} className={classes.holidayItem}>
              <div className={classnames(classes.nameDateWrapper, isMobile ? classes.mobileNameDateWrapper : null)}>
                <Typography className={classes.holidayNameWrapper} color="neutral.level2" lineHeight={18} size={16}>
                  {it.holidayName}
                </Typography>
                <Typography color="neutral.level4">{this.formatDate(it.holidayDate)}</Typography>
              </div>
              <div className={classes.buttonWrapper}>
                <Button variant="secondary" size="medium" onClick={this.editHoliday(it)}>
                  Preencher
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderHolidayList = () => {
    const {clear} = this.props;
    const {type, loading, status, message} = this.state;
    return (
      <Dialog
        open={type === HOLIDAY_LIST}
        maxWidth="md"
        onClose={clear}
        loading={loading}
        title="Feriados"
        status={status}
        message={message}
        actionLoading={loading}
        disablePrimary={loading}
        disableSecondary={loading}
        disableClose={loading}
        primaryButtonText="Fechar"
        primaryButtonAction={clear}
      >
        {this.renderListDialogContent()}
      </Dialog>
    );
  };

  render() {
    return (
      <>
        {this.renderHolidayList()}
        {this.renderHolidayRegister()}
      </>
    );
  }
}

HolidayRegisterComponent.propTypes = {
  clear: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  checkNotificationStatus: PropTypes.func.isRequired
};

export default withStyles(styles)(HolidayRegisterComponent);
