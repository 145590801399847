import Button from '@gav/button';
import IconButton from '@gav/icon-button';
import Typography from '@gav/typography';
import {withStyles} from '@material-ui/core/styles';
import AdjustIcon from '@material-ui/icons/Adjust';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import classnames from 'classnames';
import {push} from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {GOAL_REACHED_EMAIL, PENDING_HOLIDAY_STORES, REGISTER_HOLIDAY, AVAILABLE_POP_QUIZ, UPCOMING_POP_QUIZ} from '../../notifications/util/notificationTypes';
import {clear, registerHoliday, trackHolidayActivity} from '../../notificationUserActions/actions';

const styles = theme => ({
  root: {
    padding: 20,
    minWidth: 300
  },
  notDone: {
    backgroundColor: theme.colors.neutral.white
  },
  done: {
    backgroundColor: theme.colors.neutral.level10
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    width: 24,
    height: 24
  },
  iconBody: {
    padding: 0
  },
  title: {
    color: theme.colors.neutral.level2,
    width: '100%',
    padding: '0 20px 0 10px'
  },
  closeIcon: {},
  body: {
    color: theme.colors.neutral.level5,
    padding: '0 0 15px 0'
  },
  description: {},
  footer: {},
  button: {}
});

class NotificationCard extends React.Component {
  getNoticationExtraData = () => {
    const {type, push, registerHoliday, trackHolidayActivity} = this.props;

    switch (type) {
      case PENDING_HOLIDAY_STORES:
        return {
          buttonTitle: 'Ver Lojas',
          icon: EventIcon,
          buttonAction: trackHolidayActivity
        };
      case REGISTER_HOLIDAY:
        return {
          buttonTitle: 'Preencher Informações',
          icon: EventIcon,
          buttonAction: registerHoliday
        };
      case AVAILABLE_POP_QUIZ:
        return {
          buttonTitle: 'Preencher Prova POP',
          icon: EventIcon,
          buttonAction: () => push(`/operacional/prova-pop/preenchimento/${this.props.quizId}`)
        };
      case UPCOMING_POP_QUIZ:
        return {
          icon: EventIcon
        };
      case GOAL_REACHED_EMAIL:
      default:
        return {
          buttonTitle: 'Enviar compromisso',
          icon: AdjustIcon,
          buttonAction: () => push('/admin/meta-atingida')
        };
    }
  };

  render() {
    const {classes, title, description, closeButtonAction, buttonCallback, done = false, enableClose = false} = this.props;
    const {buttonTitle, icon: Icon, buttonAction} = this.getNoticationExtraData();
    const buttonClick = () => {
      buttonAction();
      buttonCallback && buttonCallback();
    };
    return (
      <div className={classnames(classes.root, done ? classes.done : classes.notDone)}>
        <div className={classes.header}>
          <div className={classes.icon}>
            <IconButton color="secondary" disabled className={classes.iconBody}>
              <Icon />
            </IconButton>
          </div>
          <div className={classes.title}>
            <Typography lineHeight={18} size={16}>
              {title}
            </Typography>
          </div>
          <div className={classes.closeIcon}>
            {enableClose && (
              <IconButton color="secondary" onClick={closeButtonAction} title="Fechar">
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.description}>
            <Typography lineHeight={20}>{description}</Typography>
          </div>
        </div>
        <div className={classes.footer}>
          {buttonTitle && buttonAction && (
            <div className={classes.button}>
              <Button variant="primary" onClick={buttonClick}>
                {buttonTitle}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NotificationCard.propTypes = {
  classes: PropTypes.object.isRequired,
  key: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  registerHoliday: PropTypes.func.isRequired,
  trackHolidayActivity: PropTypes.func.isRequired,
  buttonCallback: PropTypes.func,
  enableClose: PropTypes.bool,
  closeButtonAction: PropTypes.func,
  done: PropTypes.bool,
  quizId: PropTypes.number
};

export default connect(null, {push, clear, registerHoliday, trackHolidayActivity})(withStyles(styles)(NotificationCard));
