import {CalcIcon, StoreIcon, CensoredIcon, ViewIcon, DemoIcon, ExportIcon, DateIcon, OperationalIcon, RankingIcon} from '@gav/icons';
import {t} from '@lingui/macro';
import {StoreMallDirectory, AssignmentOutlined} from '@material-ui/icons';
import UpdateIcon from '@material-ui/icons/Update';
import React, {Component} from 'react';

import Menu from '../../app/menu/Menu';

class AdminMenuMapProvider extends Component {
  adminMenuMap = [
    {
      key: 'lojas-fechadas',
      title: t`admin.sideMenu.closedStores`,
      path: '/admin/lojas-fechadas',
      icon: StoreMallDirectory,
      children: []
    },
    {
      key: 'area-lojas',
      title: t`admin.sideMenu.storeArea`,
      path: '/admin/area-lojas',
      icon: StoreIcon,
      children: []
    },
    {
      key: 'cadastro-datas',
      title: t`admin.sideMenu.comparativeDateRegister`,
      path: '/admin/cadastro-datas',
      icon: CalcIcon,
      children: []
    },
    {
      key: 'censura-comentarios',
      title: t`admin.sideMenu.commentCensorship`,
      path: '/admin/censura-comentarios',
      icon: CensoredIcon,
      children: []
    },
    {
      key: 'importacao-cliente-oculto',
      title: t`admin.sideMenu.hiddenShopper`,
      path: '/admin/importacao-cliente-oculto',
      icon: ViewIcon,
      children: []
    },
    {
      key: 'dre',
      title: t`admin.sideMenu.dre`,
      path: '/admin/dre',
      icon: DemoIcon,
      children: []
    },
    {
      key: '/admin/exportacao-estoque',
      title: t`admin.sideMenu.exportStockData`,
      path: '/admin/exportacao-estoque',
      icon: ExportIcon,
      children: []
    },
    {
      key: '/admin',
      title: t`admin.sideMenu.exportRankingData`,
      path: '/admin',
      icon: ExportIcon,
      children: []
    },
    {
      key: 'feriados',
      title: t`admin.sideMenu.holidays`,
      path: '/admin/feriados',
      icon: DateIcon,
      children: []
    },
    {
      key: 'importa-dados',
      title: t`admin.sideMenu.sigaImporter`,
      path: '/admin/importa-dados',
      icon: UpdateIcon,
      children: []
    },
    {
      key: 'meta-atingida',
      title: t`admin.sideMenu.goalReached`,
      path: '/admin/meta-atingida',
      icon: RankingIcon,
      children: []
    },
    {
      key: 'ranking-posicao',
      title: t`admin.sideMenu.rankingPosition`,
      path: '/admin/ranking-posicao',
      icon: RankingIcon,
      children: []
    },
    {
      key: 'prova-pop',
      title: 'Prova POP',
      path: '/admin/prova-pop',
      icon: AssignmentOutlined,
      children: []
    },
    {
      key: 'quadro-operacional',
      title: t`admin.sideMenu.operationalBoard`,
      path: '/admin/quadro-operacional',
      icon: OperationalIcon,
      children: []
    }
  ];

  render() {
    return <Menu menuMap={this.adminMenuMap} />;
  }
}

export default AdminMenuMapProvider;
